import React, { useState, useEffect } from "react";
import {
  TabbedForm,
  FormTab,
  Create,
  TextInput,
  ListProps,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  CheckboxGroupInput

} from "react-admin";

import { FormControl, TextField, Checkbox, Button, Box, Select, MenuItem, InputLabel } from '@mui/material';
import { Formik } from 'formik';
import { object, string, boolean, ref } from 'yup';
import AxiosGetService from "../../api/axiosGetService";
import AxiosPostService from "../../api/axiosPostService";
import { toast, ToastContainer } from "react-toastify";

const roles = [
  { id: "branchmanager", name: "Branch Manager" },
  { id: "branchaccounting", name: "Branch Accounting" },
];


const UserCreate = (props: ListProps) => {
  const [state, setState] = useState({ selectRole: "" });
  const [branches, setBranches] = useState([]);

  const selectRole = (self: any) => {
    setState((state) => ({ ...state, selectRole: self.target.value }));
  }

  useEffect(() => {
    const fetchData = async () => {
      let branches: any = await AxiosGetService.getBranches();
      setBranches(branches);
    }
    fetchData()
  }, [])

  // return (
  //   <Create {...props}>
  //     <TabbedForm>
  //       <FormTab label="general">
  //         <TextInput source="name" />
  //         <TextInput source="lastname" />
  //         <TextInput source="email" />
  //         <TextInput source="password" />
  //         <TextInput source="passwordConfirmation" />
  //         <CheckboxGroupInput source="mainBranchManager" />
  //         <SelectInput source="role" choices={roles} onChange={selectRole} />
  //         <ReferenceInput source="branchId" reference="branch">
  //           <AutocompleteInput optionText="title" />
  //         </ReferenceInput>


  //       </FormTab>
  //     </TabbedForm>
  //   </Create>
  // );

  return (
    <Box>
      <Formik
        validateOnBlur
        initialValues={{
          name: "",
          lastname: "",
          email: "",
          password: "",
          passwordConfirmation: "",
          role: "",
          branchId: "",
          isMainManager: false
        }}

        validationSchema={
          object({
            name: string().required("Name is required"),
            lastname: string().required('Lastname is required'),
            email: string().required('Email is required'),
            password: string().min(6, 'Password is too short').required('Password is required'),
            passwordConfirmation: string().required('Password Confirmation is required').oneOf([ref('password'), null]),
            role: string().required('Role is required'),
            branchId: string().required('Branch is required'),
            isMainManager: boolean().required('Permission is required').default(false),
          })
        }

        onSubmit={async (values, { resetForm, setSubmitting }) => {
          console.log(values);
          let response: any = await AxiosPostService.createManager(values);
          if(response.status == 200){
            toast.success("Branch Manager Created");
            setTimeout(() => {
              resetForm();
              setSubmitting(false);
            }, 2000)
          }
        }}
      >
        {
          ({ values, errors, handleChange, handleSubmit, handleReset, dirty, touched, isSubmitting, handleBlur }) => (
            <form onSubmit={handleSubmit}>
              <ToastContainer position="bottom-left" />
              <Box sx={{ display: 'flex', flexDirection: 'row', margin: 'auto' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: "30vw", margin: 'auto', marginRight: '2vw' }}>
                  <TextField id="name" variant="outlined" label="Name" value={values.name} onBlur={handleBlur} onChange={handleChange} sx={{ marginTop: '1vw' }} error={errors.name && touched.name ? true : false} helperText={errors.name && touched.name ? errors.name : null} required />
                  <TextField id="lastname" variant="outlined" label="Last Name" value={values.lastname} onBlur={handleBlur} onChange={handleChange} sx={{ marginTop: '1vw' }} error={errors.lastname && touched.lastname ? true : false} helperText={errors.lastname && touched.lastname ? errors.lastname : null}  required />
                  <TextField id="email" type="email" variant="outlined" label="Email" value={values.email} onBlur={handleBlur} onChange={handleChange} sx={{ marginTop: '1vw' }} error={errors.email && touched.email ? true : false} helperText={errors.email && touched.email ? errors.email : null} required />
                  <TextField id="password" type="password" variant="outlined" label="Password" value={values.password} onBlur={handleBlur} onChange={handleChange} sx={{ marginTop: '1vw' }} error={errors.password && touched.password ? true : false} helperText={errors.password && touched.password ? errors.password : null} required />
                  <TextField id="passwordConfirmation" type="password" variant="outlined" label="Password Confirmation" onBlur={handleBlur} value={values.passwordConfirmation} onChange={handleChange} sx={{ marginTop: '1vw' }} error={errors.passwordConfirmation && touched.passwordConfirmation ? true : false} helperText={errors.passwordConfirmation && touched.passwordConfirmation ? errors.passwordConfirmation : null} required />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: "30vw", margin: 'auto' }}>
                  <InputLabel id="role-label" sx={{ marginTop: '1vw' }}>Role</InputLabel>
                  <TextField name="role" select id="role" value={values.role} onBlur={handleBlur} onChange={handleChange} error={errors.role && touched.role ? true : false} helperText={errors.role && touched.role ? errors.role : null} required>
                    <MenuItem value="branchmanager">Branch Manager</MenuItem>
                    <MenuItem value="branchaccounting">Branch Accounting</MenuItem>
                  </TextField>
                  <div>
                    <InputLabel id="isMainManager-label" sx={{ marginTop: '1vw' }}>Is Main Manager?</InputLabel>
                    <Checkbox id="isMainManager"aria-labelledby="isMainManager-label" value={values.isMainManager} onBlur={handleBlur} onChange={handleChange}  />
                  </div>
                  <InputLabel id="branch-label" sx={{marginTop: "1vw"}}>Branch</InputLabel>
                  <TextField name="branchId" select id="branchId" value={values.branchId} onBlur={handleBlur} onChange={handleChange} error={errors.branchId && touched.branchId ? true : false} helperText={errors.branchId && touched.branchId ? errors.branchId : null} required>
                      {branches.map((branch: any) => (
                        <MenuItem key={branch.id} value={branch.id}>{branch.title}</MenuItem>
                      ))}
                  </TextField>
                </Box>
              </Box>
            <Button type="submit" disabled={!dirty || isSubmitting} variant="contained" sx={{marginTop: "3vw", marginLeft: "75vw"}}>Create</Button>

            </form>
          )
        }

      </Formik>
    </Box>
  )
};

export default UserCreate;
