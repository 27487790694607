import CountryList from './CountryList';
import CountryEdit from './CountryEdit';
import CountryCreate from './CountryCreate';
//import CountryShow from './CountryShow';


export default {
    list: CountryList,
    edit: CountryEdit,
    create: CountryCreate,
};