import React from "react";
import { TabbedForm, FormTab, Edit, TextInput, ListProps } from "react-admin";

const CountryEdit = (props: ListProps) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="name" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CountryEdit;
