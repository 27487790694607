import { fetchUtils } from "ra-core";


export interface Options {
    [key: string]: any;
}

const httpClient = (url:string, options:Options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }
  
    const token = localStorage.getItem("token");
    options.headers.set("Authorization", `Bearer ${token}`);

     // add your own headers here
     options.headers.set('X-Custom-Header', 'foobar');
    return fetchUtils.fetchJson(url, options);
};
  
export default httpClient