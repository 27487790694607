
import * as React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import CloneProducts from './pages/cloneproducts/clone-products';


export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/clone-products" render={() => <CloneProducts />} />
];