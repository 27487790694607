import * as React from "react";
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  SearchInput,
  Filter,
  ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

export const BranchFilter = (props: ListProps) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  hiddenOnSmallScreens: {
    display: "table-cell",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const BranchList = (props: ListProps) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      filters={<BranchFilter />}
      perPage={25}
      sort={{ field: "date", order: "desc" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="custom_id" />
        <TextField source="title" />
        <TextField source="address" />
        <TextField source="crew_quota" />
        <ReferenceField source="city" reference="city">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="district" reference="district">
          <TextField source="name" />
        </ReferenceField>
        
      </Datagrid>
    </List>
  );
};

export default BranchList;
