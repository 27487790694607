import React from "react";
import {
  TabbedForm,
  FormTab,
  Edit,
  TextInput,
  ListProps,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

const CityEdit = (props: ListProps) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="name" />
          <ReferenceInput source="country" reference="country">
            <AutocompleteInput source="name" />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CityEdit;
