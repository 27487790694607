import * as React from "react";
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  SearchInput,
  DateInput,
  Filter,
  TextInput,
  CardActions
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const NoneActions = (props: ListProps) => (
  <CardActions />
);
export const UserFilter = (props: ListProps) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  hiddenOnSmallScreens: {
    display: "table-cell",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const UserList = (props: ListProps) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      filters={<UserFilter />}
      perPage={25}
      sort={{ field: "date", order: "desc" }}
      actions={<NoneActions />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="lastname" />
        <EmailField source="email" />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default UserList;
