import React from 'react';
import { Box, TextField, Select, MenuItem, InputLabel, FormControlLabel, Checkbox, Button, IconButton } from '@mui/material';
import axios, { AxiosRequestHeaders } from 'axios';
import AxiosGetService from '../../api/axiosGetService';
import { createInferTypeNode } from 'typescript';
import { CreateBranchDocumnet } from '../../api/libary/post';
import AxiosPostService from '../../api/axiosPostService';
import { ToastContainer, toast } from 'react-toastify';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import 'react-toastify/dist/ReactToastify.css';


interface BranchCreateGeneralState {
    countries: any[],
    allCities: any[],
    allDistricts: any[],
    selectedCities: any[],
    selectedDistricts: any[],
    selectedCountry: string,
    selectedCity: string,
    selectedDistrict: string,
    title: string,
    address: string,
    crewQuota: number,
    isSubBranch: boolean,
    mainBranch: string,
    branches: any[],
    image: any,
    isProductsPrivate: boolean,
}

class BranchCreateGeneral extends React.Component {
    state: BranchCreateGeneralState = {
        countries: [],
        allCities: [],
        allDistricts: [],
        selectedCities: [],
        selectedDistricts: [],
        selectedCountry: "",
        selectedCity: "",
        selectedDistrict: "",
        title: "",
        address: "",
        isSubBranch: true,
        mainBranch: "",
        crewQuota: 0,
        branches: [],
        image: "",
        isProductsPrivate: false

    }

    async componentDidMount() {
        let branches = await AxiosGetService.getBranches();
        this.setState({
            countries: await AxiosGetService.getCountries(),
            allCities: await AxiosGetService.getCities(),
            allDistricts: await AxiosGetService.getDistricts(),
            branches: branches.filter((b: any) => b.manageBranch)
        })

    }

    render() {
        const resetState = () => {
            this.setState({
                selectedCountry: "",
                selectedCity: "",
                selectedDistrict: "",
                isSubBranch: true,
                mainBranch: "",
                title: "",
                address: "",
                crewQuota: 0,
                image: ""

            })
        }
        const handleChange = (e: any, type: string) => {
            switch (type) {
                case "country":
                    this.setState({
                        selectedCountry: e.target.value,
                        selectedCities: this.state.allCities.filter((city: any) => city.country == e.target.value)
                    });
                    break;
                case "city":
                    this.setState({
                        selectedCity: e.target.value,
                        selectedDistricts: this.state.allDistricts.filter((district: any) => district.city == e.target.value)
                    });
                    break;
                case "district":
                    this.setState({
                        selectedDistrict: e.target.value
                    });
                    break;
                case "isSubBranch":
                    this.setState({
                        isSubBranch: !this.state.isSubBranch
                    })
                    break;
                case "address":
                    this.setState({
                        address: e.target.value
                    });
                    break;
                case "title":
                    this.setState({
                        title: e.target.value
                    });
                    break;
                case "crew_quota":
                    this.setState({
                        crewQuota: e.target.value
                    });
                    break;
                case "isProductsPrivate":
                    this.setState({
                        isProductsPrivate: !this.state.isProductsPrivate
                    })
                    break;
                default:
                    break;
            }
        }
        const success = () => toast.success('Branch Created!', {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        const changeImage = (e: any) => {
            const file = e.target.files[0];

            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ image: reader.result })
            };
            reader.readAsDataURL(file);
        }
        const error = (message: string) => toast.error(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
        const handleCreate = async () => {
            if (this.state.title.length < 1 || this.state.address.length < 1 || this.state.selectedCity.length < 1 || this.state.selectedCountry.length < 1 || this.state.selectedDistrict.length < 1 || this.state.crewQuota == 0 || this.state.isSubBranch ? this.state.mainBranch.length < 1 : false) {
                error("Please fill in the required fields.");
                return;
            }
            let data: CreateBranchDocumnet = {
                title: this.state.title,
                address: this.state.address,
                country: this.state.selectedCountry,
                city: this.state.selectedCity,
                district: this.state.selectedDistrict,
                crew_quota: Number(this.state.crewQuota),
                isSubBranch: this.state.isSubBranch,
                image: this.state.image || "",
                isProductsPrivate: this.state.isProductsPrivate
            }
            if (data.isSubBranch) data.mainBranch = this.state.mainBranch;

            let response = await AxiosPostService.createBranch(data);
            if (response.status == 200) {
                success();
                resetState();
                return;
            }
            error("Server error");
            return;

        }
        const useMainBranchImage = () => {
            if(!this.state.mainBranch) return;
            let mainBranch = this.state.branches.find((b: any) => b.id == this.state.mainBranch);
            if(!mainBranch || !mainBranch?.image) {
                error("The selected main branch does not have an image");
                return;
            }
            this.setState({
                image: mainBranch.image
            });
            
        }
        return (
            <>
                <ToastContainer />

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                            <img src={this.state.image ? this.state.image : "https://manager.digigarson.com/static/images/not-available.png"} style={{ width: "7vw", height: "13vh", borderRadius: "50px", margin: "auto", marginTop: 4, border: "none" }} />
                            <label htmlFor="fileInput" style={{ margin: "auto", border: "2px solid black", padding: "10px", backgroundColor: "gray", color: "#fff", cursor: "pointer", borderRadius: "10px", width: "15vw", textAlign: "center" }}>Select Image</label>
                            <input
                                id="fileInput"
                                type="file"
                                accept="image/*"
                                onChange={(e: any) => changeImage(e)}
                                multiple={false}
                                hidden
                            />
                            <Button onClick={useMainBranchImage} sx={{width: "20", height: "10vh"}} variant="contained" color="info" disabled={!this.state.isSubBranch || !this.state.mainBranch}>Use Main Branch's Image</Button>
                        </div>
                        <TextField value={this.state.title} sx={{ width: "40vw", margin: 3 }} variant='filled' label="Title" onChange={(e: any) => handleChange(e, 'title')} required />
                        <InputLabel sx={{ marginLeft: 3 }} id="country" required>Country</InputLabel>
                        <Select variant='filled' sx={{ width: "40vw", marginLeft: 3 }} labelId="country" id="country-select" value={this.state.selectedCountry} label="Country" onChange={(e: any) => handleChange(e, 'country')} required>
                            {this.state?.countries?.map((country: any, index: number) => (
                                <MenuItem value={country.id} key={index}>{country.name}</MenuItem>
                            ))}
                        </Select>
                        {this.state.selectedCountry ? (
                            <>
                                <InputLabel sx={{ marginLeft: 3 }} id="city" required>City</InputLabel>

                                <Select variant='filled' sx={{ width: "40vw", marginLeft: 3 }} labelId="city" id="city-select" value={this.state.selectedCity} label="City" onChange={(e: any) => handleChange(e, 'city')}>

                                    {this.state?.selectedCities?.map((city: any, index: number) => (
                                        <MenuItem value={city.id} key={index}>{city.name}</MenuItem>
                                    ))}
                                </Select>
                            </>

                        ) : null}
                        {this.state.selectedCity ? (
                            <>
                                <InputLabel sx={{ marginLeft: 3 }} id="district" required>District</InputLabel>

                                <Select variant="filled" sx={{ width: "40vw", marginLeft: 3 }} labelId="district" id="district-select" value={this.state.selectedDistrict} label="District" onChange={(e: any) => handleChange(e, 'district')}>

                                    {this.state?.selectedDistricts?.map((district: any, index: number) => (
                                        <MenuItem value={district.id} key={index}>{district.name}</MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : null}
                        <TextField value={this.state.address} sx={{ width: "40vw", margin: 3 }} variant='filled' label="Address" multiline rows={5} onChange={(e: any) => handleChange(e, 'address')} required />

                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField value={this.state.crewQuota} sx={{ width: "40vw", margin: 3 }} variant="filled" label="Crew Quota" type="number" onChange={(e: any) => handleChange(e, 'crew_quota')} required />
                        <FormControlLabel sx={{ margin: 3 }} control={<Checkbox checked={!this.state.isSubBranch} onChange={(e: any) => handleChange(e, 'isSubBranch')} />} label="Main Branch" />
                        {this.state.isSubBranch ? (
                            <>
                                <InputLabel id="branch" sx={{ marginLeft: 3, marginTop: 3, marginBottom: 0, marginRight: 3 }} required>Select Main Branch</InputLabel>
                                <Select value={this.state.mainBranch} onChange={(e: any) => this.setState({ mainBranch: e.target.value })} labelId="branch" variant='filled' sx={{ width: "40vw", marginLeft: 3 }}>
                                    {this.state.branches?.map((branch: any, index: number) => (
                                        <MenuItem value={branch.id} key={index}>{branch.title}</MenuItem>
                                    ))}
                                </Select>
                            </>
                        ) : (
                            <FormControlLabel sx={{ margin: 3 }} control={<Checkbox checked={this.state.isProductsPrivate} onChange={(e: any) => handleChange(e, 'isProductsPrivate')} />} label="Let only the main branch do the product arrangement" />

                        )}
                        <Button variant="contained" sx={{ width: "20vw", margin: "auto" }} color="success" type="submit" onClick={() => handleCreate()}>
                            Create
                        </Button>
                    </Box>
                </Box>

            </>


        )
    }
}

export default BranchCreateGeneral;