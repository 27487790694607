import React, { useState } from "react";
import {
  TabbedForm,
  FormTab,
  Create,
  TextInput,
  ListProps,
  SelectInput,
  ReferenceInput,
  AutocompleteInput

} from "react-admin";

const roles = [
  { id: "regionalmanager", name: "Regional Manager" }
];


const UserCreate = (props: ListProps) => {
  const [state, setState] = useState({ selectRole: "" });

  const selectRole = (self: any) => {
    setState((state) => ({ ...state, selectRole: self.target.value }));
  }

  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="name" />
          <TextInput source="lastname" />
          <TextInput source="email" />
          <TextInput source="password" />
          <TextInput source="passwordConfirmation" />
          <SelectInput source="role" choices={roles} onChange={selectRole} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default UserCreate;
