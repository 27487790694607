import React, { useState, useEffect } from 'react'
import { Box, Button, TextField } from '@mui/material';
import AxiosGetService from '../../api/axiosGetService';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';
import AxiosPostService from '../../api/axiosPostService';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function CloneProducts() {
    const [branches, setBranches]: any = useState([]);
    useEffect(() => {
        const fetchBranches = async () => {
            let branches = await AxiosGetService.getBranches();
            let b = branches.map((branch: any) => {
                return {
                    label: branch.title,
                    value: branch.id,
                    id: branch.id
                }
            })
            setBranches(b);
        }
        fetchBranches();
    }, []);
    const [selectedBranch, setSelectedBranch] = useState();
    const [targetBranch, setTargetBranch] = useState();
    const [onPromise, setPromise] = useState(false);

    const handleCloneProducts = async () => {
        if (!selectedBranch || !targetBranch) {
            toast.warning("Please select main branch and target branch");
            return;
        }
        if (selectedBranch == targetBranch) {
            toast.warning("Main branch and target branch cannot be same");
            return;
        }
        setPromise(true);
        let res = await AxiosPostService.cloneProducts({ branchId: selectedBranch, targetBranchId: targetBranch });
        if (res.status == 200) {
            setPromise(false);
            toast.success("Products cloned successfully")

            return;
        }
        setPromise(false);
        toast.error(`Internal Server Error - Error Code: ${res.status}`)
    }
    return (
        <Box>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Backdrop open={onPromise}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContenet: "space-around" }}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <Box>
                        Branch
                        <Select
                            options={branches}
                            onChange={(opt: any) => {
                                setSelectedBranch(opt.id)
                            }}
                            isDisabled={onPromise}
                        />
                    </Box>
                    <Box>
                        Target Branch
                        <Select
                            options={branches}
                            onChange={(opt: any) => {
                                setTargetBranch(opt.id)
                            }}
                            isDisabled={onPromise}
                        />
                    </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button variant="contained" color="success" onClick={() => handleCloneProducts()} disabled={onPromise}>Clone Products</Button>
                </Box>
            </Box>

        </Box>
    )
}
