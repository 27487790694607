import axios, {AxiosRequestConfig} from "axios"
require('dotenv').config()

export default class AxiosGetService{
    public static getConfig() {
        let authToken = localStorage.getItem('token');
        let refreshToken = localStorage.getItem('dgm-refresh-token');
        return {
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'x-refresh': refreshToken 
            },
        } as AxiosRequestConfig
    }

    public static async getCountries() {
        let response = await axios.get('/country', AxiosGetService.getConfig())
        return response.data;
    }

    public static async getCities() {
        let response = await axios.get('/city', AxiosGetService.getConfig())
        return response.data;
    }

    public static async getDistricts() {
        let response = await axios.get('/district', AxiosGetService.getConfig())
        return response.data;
    }

    public static async getBranches() {
        let response = await axios.get('/branch', AxiosGetService.getConfig());
        return response.data;
    }
}