import React from "react";
import { TabbedForm, FormTab, Create, TextInput, ListProps } from "react-admin";

const CountryCreate = (props: ListProps) => {
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="name" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CountryCreate;
