import React from "react";
import {
  TabbedForm,
  FormTab,
  Show,
  CardActions,
  TextField,
  ListProps,
} from "react-admin";

const permissions = [
  { id: 0, name: "Süper Admin Erişim İzni" },
  { id: 200, name: "Regional Manager Erişim İzini" },
  { id: 300, name: "Branch Manager Erişim İzni" },
  { id: 400, name: "Garson Erişim İzni" },
  { id: 500, name: "Pos Erişim İzni" },
  { id: 1000, name: "Müşteri Kullanıcısı" },
];
const NoneActions = (props: ListProps) => (
  <CardActions />
);
const UserShow = (props: ListProps) => {
  return (
    <Show {...props}
    actions={<NoneActions />}>
      <TabbedForm>
        <FormTab label="general">
          <TextField source="name" />
          <TextField source="lastname" />
          <TextField source="email" />
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default UserShow;
