import { Box } from '@mui/material';
import BranchCreateGeneral from '../../components/branchcreate/branch-create-general';

const BranchCreate = () => {
  return (
    <Box>
      <BranchCreateGeneral />
    </Box>
  )
}

export default BranchCreate;
