import * as React from "react";
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  SearchInput,
  Filter,
  ReferenceField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

export const CityFilter = (props: ListProps) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput source="country_id" reference="country">
      <AutocompleteInput source="name" />
    </ReferenceInput>
  </Filter>
);

const useStyles = makeStyles((theme) => ({
  hiddenOnSmallScreens: {
    display: "table-cell",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const CityList = (props: ListProps) => {
  const classes = useStyles();
  return (
    <List
      {...props}
      filters={<CityFilter />}
      perPage={25}
      sort={{ field: "date", order: "desc" }}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <ReferenceField source="city" reference="city">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="country" reference="country">
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export default CityList;
