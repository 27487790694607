import React from "react";
import {
  TabbedForm,
  FormTab,
  Edit,
  TextInput,
  ListProps,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
} from "react-admin";

const BranchEdit = (props: ListProps) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="title" />
          <ReferenceInput source="country" reference="country">
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <ReferenceInput source="city" reference="city">
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <ReferenceInput source="district" reference="district">
            <AutocompleteInput source="name" />
          </ReferenceInput>
          <TextInput source="address" />
        </FormTab>
        <FormTab label="crew">
          <NumberInput source="crew_quota" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default BranchEdit;
