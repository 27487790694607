import React from "react";
import {
  TabbedForm,
  FormTab,
  Edit,
  TextInput,
  ListProps,
  SelectArrayInput,
} from "react-admin";

const permissions = [
  { id: 0, name: "Süper Admin Erişim İzni" },
  { id: 200, name: "Regional Manager Erişim İzini" },
  { id: 300, name: "Branch Manager Erişim İzni" },
  { id: 400, name: "Garson Erişim İzni" },
  { id: 500, name: "Pos Erişim İzni" },
  { id: 1000, name: "Müşteri Kullanıcısı" },
];

const UserEdit = (props: ListProps) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="general">
          <TextInput source="name" />
          <TextInput source="lastname" />
          <TextInput source="email" />
          <SelectArrayInput source="permissions" choices={permissions} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default UserEdit;
