import * as React from "react";
import UsersIcon from "@mui/icons-material/SupervisedUserCircle";
import { useTranslate } from "react-admin";

import CardWithIcon from "./CardWithIcon";

interface Props {
  path: string;
  icon: any;
  title: any;
  value?: number;
}

const Card = (props: Props) => {
  const { path, icon, title, value } = props;
  return <CardWithIcon to={path} icon={icon} title={title} subtitle={value} />;
};

export default Card;
