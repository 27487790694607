import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";

import authProvider from "./providers/authProvider";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";
// Resources
import users from "./pages/users";
import branchmanagers from "./pages/branchmanagers";
import admins from "./pages/admins";
import branchcrewusers from "./pages/branchcrewusers";
import branch from "./pages/branch";
import district from "./pages/district";
import city from "./pages/city";
import country from "./pages/country";

import httpClient from "./providers/httpClient";
import jsonServerProvider from "ra-data-json-server";

require("dotenv").config();

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");

const resourceList = [
  { name: "users", resource: users },
  { name: "branchmanagers", resource: branchmanagers },
  { name: "admins", resource: admins },
  { name: "branchcrewusers", resource: branchcrewusers },
  { name: "branch", resource: branch },
  { name: "district", resource: district },
  { name: "city", resource: city },
  { name: "country", resource: country },
];

const apiUrl = process.env.REACT_APP_API_URL;
const App = () => {
  return (
    <Admin
      title="Super Admin"
      dataProvider={jsonServerProvider(apiUrl, httpClient)}
      customReducers={{ theme: themeReducer }}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      disableTelemetry
    >
      {resourceList.map((i, idx) => (
        <Resource key={idx} name={i.name} {...i.resource} />
      ))}
    </Admin>
  );
};

export default App;
